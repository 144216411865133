<template>

  <div class="form">

    <v-form
      ref="form"
      class="pa-6"
      v-model="valid"
    >
      <Loading :value="loading" />
      <div class="pa-0 pb-6">
        <v-select
          prepend-inner-icon="mdi-domain"
          :disabled="!isAdmin"
          v-model="company_id"
          :items="companies"
          item-text="title"
          item-value="id"
          :label="$tc('COMPANY.TITLE')"
          hide-details
          :rules="[$rules.required]"
          required
          outlined
          @change="loadOffices"
        />
      </div>

      <div class="pa-0">
        <v-select
          prepend-inner-icon="mdi-office-building"
          v-model="office_id"
          :items="offices"
          :label="$tc('OFFICE.TITLE')"
          item-text="title"
          item-value="id"
          :rules="[$rules.required]"
          required
          outlined
          hide-details
        />
      </div>
    </v-form>

    <div class="text-center pa-4 pt-0">
      <v-btn
        color="success"
        rounded
        depressed
        :disabled="!valid"
        large
        @click="validate"
      >
        <span>{{ $t('FORM.SWITCH_ACCOUNT_BTN') }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import { mapState } from 'vuex'
export default {
  name: 'FormSwitchAccount',
  components: {
    Loading
  },
  data: () => ({
    loading: false,
    company_id: null,
    office_id: null,
    companies:[],
    offices:[],
    valid: false,
  }),

  computed: {
    ...mapState("user", {
      user: (state) => state.user,
      isAdmin: (state) =>
        state.user && state.user.role.title.includes("admin"),
    })
  },

  mounted () {
    if (this.isAdmin) this.loadCompanies()
    else {
      this.companies = [{...this.user.company, id: this.user.company_id}]
      this.company_id = this.user.company_id
      this.loadOffices()
    }
  },

  watch: {
    loading (n) {
      this.$emit('loading', n)
    }
  },

  methods: {

    validate () {
      this.loading = true
      if (this.$refs.form.validate()) {
        this.$store.dispatch('user/switch', {
          company_id: this.company_id,
          office_id: this.office_id,
        })
          .then(() => {
            this.$store.dispatch('app/init')
          })
          .then(() => {
            this.close()
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    loadCompanies () {
      this.valid = false
      this.loading = true
      this.$services.api_user.company.search({
        options: { order:[['title','ASC']] }
      })
        .then(response => {
          this.companies = response.data
          if (this.companies.length > 0) {
            this.company_id = this.companies[0].id
            this.loadOffices()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadOffices () {
      this.loading = true
      this.$services.api_user.office.search({
        where: { company_id: this.company_id },
        options: { order:[['title','ASC']] }
      })
        .then(response => {
          this.offices = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },

    close () {
      this.$emit('close')
    },
  }
}
</script>
